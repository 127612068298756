<template>
  <div id="app"  >

  <div class="fill-height">
   
    <AppHeaderLayout />

  </div>
  </div>
</template>

<script>

import AppHeaderLayout from "@/modules/shared/components/app-header-layout";
export default {
  components: {
  
    AppHeaderLayout,
    
  },
 
};
</script>

<style lang="scss">
@font-face {
  font-family: "Philosopher-Bold";
  src: local("Philosopher-Bold"),
   url(./fonts/Philosopher-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "Philosopher-Regular";
  src: local("Philosopher-Regular"),
   url(./fonts/Philosopher-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "gess-bold";
  src: local("gess-bold"),
   url(./fonts/gess-bold.otf) format("truetype");
}
@font-face {
  font-family: "gess-Light";
  src: local("gess-Light"),
   url(./fonts/gess-Light.otf) format("truetype");
}
@font-face {
  font-family: "gess-Medium";
  src: local("gess-Medium"),
   url(./fonts/gess-Medium.otf) format("truetype");
}
html{
  font-size: 15px;
}
*{
  color: #515251;

}
.v-overlay__scrim {
      background: url('~@/assets/mission-pattern.png')#45184b !important;
      background-repeat: no-repeat !important;
      background-position: bottom right !important;
     
}
.ltr{
     font-family:"Philosopher-Regular" !important;

}
.dialog-style {
   box-shadow: unset !important;
   
}
  .flip{
    transform: scaleX(-1);
  }
 .boldTxtEn{
  font-family:"Philosopher-Bold" !important;
  font-size: 2.5rem !important;
  color: #86447E;

  }
  .areaTxtEn{
   font-family:"Philosopher-Regular";

}
.areaTxtAr{
  font-family: "gess-Light" !important;

}
.pink-bg{
  background: #FEF6FA;
  
}
.pos-relative{
  position: relative;
}

   .boldTxtAr{
  font-family:"gess-bold" !important;
  font-size: 30px !important;
  color: #86447E;

  }

  .v-text-field > .v-input__control > .v-input__slot:before {
    border: unset !important;
}
.v-text-field.v-text-field--enclosed .v-text-field__details{
  margin-bottom: 0 !important;
}

.theme--dark.v-btn--active:hover::before, .theme--dark.v-btn--active::before{
  opacity:0 !important;
}

.v-window__prev.button.v-btn.v-btn--is-elevated.v-btn--has-bg.theme--dark.v-size--default{
      border-radius: 0 !important;
    padding: 7
px
;
    width: 36
px
;
}
.mob-link{
  text-decoration: none !important;
  color: unset !important;
}
.ar-font{
  letter-spacing: 0px !important;
  font-size: 14px !important;
}
.rtl{
  direction: rtl;
    font-family:"gess-Light" !important;

}
.msgAr {
       .theme--light.v-label{
          margin-right: 15px;
       }
}
@media only screen and (max-width: 768px) {
   .v-dialog__content {
 
    margin-left: 20%;
   
}
   div#app {
    overflow: hidden;
}
  
 .boldTxtEn{
  font-family:"Philosopher-Bold" !important;
      font-size: 1.8rem !important;
    letter-spacing: 2px;

  }
     .boldTxtAr{
  font-family:"gess-bold" !important;
  font-size: 1.8rem !important;
      font-size: 2rem;
 
  }
    .v-carousel--hide-delimiter-background .v-carousel__controls {
    justify-content: center !important;
  }
.v-carousel__controls{
  justify-content:center !important ;
}
}


</style>
