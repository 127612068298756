import Vue from "vue";
import VueRouter from "vue-router";

import i18n from '../i18n'



Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: `${i18n.locale}/home`
  },
  {
    path: '/:lang',
    redirect: `${i18n.locale}/home`,
    component: {
      render: h => h('router-view')
    },
    children: [
      {
        path: "home",
        name: "home",
        component: () =>
        import("@/modules/homepage/index"),  
          meta: {
          noHeader: true,
        },
       
     
      },
      {path: "about",
      name: "about",
      component: () =>
        import("@/modules/about/index"),  
      meta: {
        noHeader: false,
        title: {En:"ABOUT US", Ar:"نبذه عنا"},
        SubTitle:{En:"Who We Are", Ar:"من نحن"},
        img:require('@/assets/header.png'),
        imgMob:require('@/assets/head-mob.png')


      },
      children: [
        {
          path: "WhoWeAre",
          name: "WhoWeAre",
          component: () =>
            import("../modules/about/components/WhoWeAre"),
            meta: {
              noHeader: false,
              title: {En:"ABOUT US", Ar:"نبذه عنا"},
              SubTitle:{En:"Who We Are", Ar:"من نحن"},
              img:require('@/assets/header.png'),
              imgMob:require('@/assets/head-mob.png')

      
            },
        },
         {
          path: "MissionVisionValues",
           name: "MissionVisionValues",
          component: () =>
             import("../modules/about/components/mission"),
             meta: {
              noHeader: false,
              title: {En:"ABOUT US", Ar:"معلومات عنا"},
                      SubTitle:{En:"MISSION & VISION & VALUES",Ar:" مهمتنا و رؤيتنا و قيمنا"},
              img:require('@/assets/header.png'),
              imgMob:require('@/assets/head-mob.png')

      
            },
         },
     
      ],
    },
      {path: "areas",
      name: "areas",
      component: () =>
      import("@/modules/areas/index"),  
            meta: {
        noHeader: false,
        title: {En:"Areas", Ar:"المناطق"},
        SubTitle:{En:"Al Woroud Areas", Ar:"مناطق الورود"},
        img:require('@/assets/header.png'),
        imgMob:require('@/assets/head-mob.png')


      },
    },
 /*       {path: "mission",
      name: "mission",
      component: () =>
      import("@/modules/mission/index"),        meta: {
        noHeader: false,
                title: {En:"ABOUT US", AR:"من نحن" },
                SubTitle:{En:"MISSION & VISION & VALUES",AR:" مهمتنا و رؤيتنا و قيمنا"},
        img:require('@/assets/header.png')

      },
    }, */
    {path: "media",
    name: "media",
    component: () =>
    import("@/modules/media/index"),   
       
    children: [
      {
        path: "news",
        name: "news",
        component: () =>
          import("../modules/media/components/news"),
          meta: {
            noHeader: false,
            title: {En:"Media", Ar:" وسائط"},
            SubTitle:{En:"EVENTS & NEWS", Ar:"  اخبار و المناسبات"},
            img:require('@/assets/header.png'),
            imgMob:require('@/assets/head-mob.png')

      
          }
      }, 
      {
        path: "news/:id",
        name: "news-item",
        component: () =>
          import("../modules/media/components/news-item"),
          meta: {
            noHeader: false,
            title: {En:"Media", Ar:" وسائط"},
            SubTitle:{En:"EVENTS & NEWS", Ar:"  اخبار و المناسبات"},
            img:require('@/assets/header.png'),
            imgMob:require('@/assets/head-mob.png')

          }
      }, {
        path: "gallery",
        name: "gallery",
        component: () =>
          import("../modules/media/components/gallery"),
          meta: {
            noHeader: false,
            title: {En:"Media", Ar:" وسائط"},
            SubTitle:{En:"Gallery", Ar:" معرض الصور"},
            img:require('@/assets/header.png'),
            imgMob:require('@/assets/head-mob.png')
          }
      },
      
      
      
   
    ],
  },
  {path: "contact",
  name: "contact",
  component: () =>
  import("@/modules/contact/index"),  
    meta: {
    noHeader: false,
    title: {En:"CONTACT US", Ar:"تواصل معنا"},
    SubTitle:{En:"Get In Touch", Ar:"تواصل معنا"},
    img:require('@/assets/contact2.png'),
    imgMob:require('@/assets/contact-mob.png')

  },
}
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  linkActiveClass: "active",
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;


