<template >
    <section class="sectionheader fill-height " v-bind:class="{'swap':($i18n.locale === 'ar')}">
      <div class="conOfHeader" >
                <img v-bind:class="$i18n.locale === 'ar' ?' logoImgAr' : 'logoImg'" src="@/assets/woroud-logo.svg" alt="logo" @click="$router.push({name:'home'})" />
            <img :src="image" class="header-img" v-bind:class="$i18n.locale === 'ar' ?' flip' : ''" >
              <div class="vertiC">
                 <div class="header-subtext text-center" v-bind:class="{'main-font':($i18n.locale === 'ar')}">
                {{ $t('home')}}
                 <v-icon style="color: #ffffff52; margin-right:10px;" dark >
                  mdi-minus
                </v-icon>
                 <span style="color: #F167A7;" v-bind:class="{'main-font':($i18n.locale === 'ar')}" @change="changeLang()">{{ headerPage }} </span>

             </div>
              <div class="header-text " v-bind:class="{'main-font':($i18n.locale === 'ar')}">{{ headerSubTitle }} </div>
            </div>

      </div>
    </section>
<!--   <v-col class="d-flex justify-start">
    
    <div class="left">
  leftSidebar
  </div>
  </v-col> -->
</template>
<script>
export default {
  props: ["headerPage",
  "headerSubTitle",
  "image"
  ],
  methods:{
    changeLang(){
      if(this.$i18n.locale === 'ar'){
        if(this.headerPage==="ABOUT US"){
        this.headerPage="من نحن"
       
      }else if(this.headerPage==="CONTACT US"){
                this.headerPage="اتصل بنا"

      
      }else if(this.headerPage==="Areas"){
                this.headerPage="المناطق"


      }else if(this.headerPage==="NEWS & EVENTS"){
                this.headerPage="اخبار"

      }else if(this.headerPage==="MISSION & VISION"){
                this.headerPage="مهمتنا و رؤيتنا"

      }
      }
    },
  },
  created(){
    this.changeLang();

  },
  updated(){
    this.changeLang();
  }
};
</script>
<style lang="scss" scoped>
.sectionheader{
  width: 100%;
  height: 350px;
    color: #fff;
}
.conOfHeader{
  position: relative;
  width: 100%;
    height: 350px;
}
.swap{
      margin-left: unset;

right: 50px;
}
.header-img{
    width: 100%;
    height: 100%;
    position: absolute;
}
.header-text{
   font-family:"Philosopher-Bold";
    font-size: 2.5rem;
    letter-spacing: 4px;
    color: #ffff;

}
.header-subtext {
     font-family:"Philosopher-Regular";
    font-size: 18px;
    color:#ffffff52;
    letter-spacing: 3px;
    

}

  .logoImgAr{
            position: absolute;
    top: 5px;
    right: 0;
    z-index: 1;
     width: 25%;
    height: 25%;
    cursor: pointer;
  }
.vertiC {  
    width: 100%;
     text-align: center;
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%);
  }
  .main-font{
    font-family: "gess-Light" !important;
  letter-spacing: 0 !important;
  }
  .logoImg{
       position: absolute;
    top: 5%;
    left: 0%;
    z-index: 1;
    width: 25%;
    height: 25%;
    cursor: pointer;
  }
  ::v-deep{

  }
  @media only screen and (max-width: 768px) {
    .conOfHeader{
  position: relative;
  width: 100%;
    height: 270px;
}
.sectionheader{
  width: 100%;
  height: 270px;
    color: #fff;
}
    .header-text{
   font-family:"Philosopher-Bold";
    font-size: 2rem;
    letter-spacing: 3px;
    color: #ffff;

}
 .logoImg{
        position: absolute;
    top: 0%;
    left: 2%;
    z-index: 1;
    width: 25%;
    height: 25%;
    cursor: pointer;
}
  }
</style>
