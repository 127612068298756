import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from "./router";
import AnimeJS from 'vue-plugin-animejs';
import VueScrollSnap from "vue-scroll-snap";
import i18n from './i18n';
import store from "./store";
import VueResource from 'vue-resource'
import Toasted from 'vue-toasted';
import * as VueGoogleMaps from "vue2-google-maps"
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import AOS from 'aos'
import 'aos/dist/aos.css'
Vue.use(CoolLightBox)
Vue.use(Toasted)
Vue.use(VueResource);
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyBeQCA6ovlwTmXBssElDNMFx5rKKCBaZ7Y",
    libraries: "places"
  }
});
Vue.config.productionTip = false;
Vue.filter('str_limit', function (value, size) {
  if (!value) return '';
  value = value.toString();

  if (value.length <= size) {
    return value;
  }
  return value.substr(0, size) + '...';
});
router.beforeEach((to, from, next) => {

  // use the language from the routing param or default language
  let language = to.params.lang;
  if (!language) {
    language = 'en'
  }
  document.documentElement.setAttribute("lang", language)
  i18n.locale = language
  next()
})

new Vue({
  vuetify,
  router,
  AnimeJS,
  store,
  
  VueScrollSnap,
    i18n,
    mounted() {
      AOS.init()
    },
  render: (h) => h(App),
}).$mount("#app");
