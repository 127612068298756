<template>
  <v-app class="v-app-Style fill-height">
<div class="d-flex fill-height">


             <Menu/>      
<Social />
 
     <main class="mainStyle"  v-bind:class="$i18n.locale === 'ar' ?' rtl' : 'ltr'" >
    <div class="headerStyle">
        <Header class="d-none d-md-block" v-if="!$route.meta.noHeader && $route.meta.SubTitle"  :image="$route.meta.img" :headerPage="$i18n.locale === 'ar' ? $route.meta.title.Ar : $route.meta.title.En" 
      :headerSubTitle="$i18n.locale === 'ar' ? $route.meta.SubTitle.Ar : $route.meta.SubTitle.En" />
        <Header class="d-block d-md-none" v-if="!$route.meta.noHeader && $route.meta.SubTitle"  :image="$route.meta.imgMob" :headerPage="$i18n.locale === 'ar' ? $route.meta.title.Ar : $route.meta.title.En" 
      :headerSubTitle="$i18n.locale === 'ar' ? $route.meta.SubTitle.Ar : $route.meta.SubTitle.En" />
    </div>

      <router-view></router-view>
        <div class="conOfAllFooterSection">
          <Footer />
          <FooterMob />
    </div>
    </main>


</div>
 


  </v-app>
</template>

<script>

import Header from "@/modules/shared/components/header-bar";
import Menu from "@/modules/shared/components/menu-bar";
import Footer from "@/modules/shared/components/footer";
import Social from "@/modules/shared/components/social";
import FooterMob from "@/modules/shared/components/footer-mob"


export default {
  components: {
   Menu,
   Social,
    Footer,
    FooterMob,
    Header,
   
  },
   data: () => ({
     title:""
   }),
 methods:{
    ChangeT(title)
    {
      this.title=title;
    },
}
 
};
</script>
<style lang="scss" scoped>
.mainStyle{
    width: 100%
  
}

.rtl{
  direction: rtl;
  font-family: "gess-Light" !important;
  letter-spacing: 0 !important;



}
</style>