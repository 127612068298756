<template>
  <section   v-bind:class="$i18n.locale === 'ar' ?' conOfFooterAr' : 'conOfFooter'" >
     <!--  <v-container>
        <v-row>
          <v-col lg="3" md="4" cols="12" class="d-flex justify-center">
        
              <div class="d-flex align-center">
                <img class="leftLogo" src="@/assets/footer-logo.png" alt="logo" />
              
              </div>
          </v-col>
         <v-col lg="8" md="7" cols="12" class="d-flex align-center justify-end "   v-bind:class="{'rtl':($i18n.locale === 'ar')}">

           <v-footer
           color="transparent"
    padless
       class="foot"
  >
    <v-row
      justify="center"
      no-gutters
      
    >
      <v-btn
        color="#ffffffab"
        text
        class="my-2"
         v-bind:class="{'ar-font':($i18n.locale === 'ar')}"
          @click="$router.push({path:'home'})"
      >
      {{ $t('home')}}
      </v-btn>
       <v-btn
        color="#ffffffab"
        text
        class="my-2"
                 v-bind:class="{'ar-font':($i18n.locale === 'ar')}"

          @click="$router.push({path:'about'})"
      >
      {{ $t("about")}}
      </v-btn>
 
           <v-btn
        color="#ffffffab"
        text
                 v-bind:class="{'ar-font':($i18n.locale === 'ar')}"
          @click="$router.push({path:'areas'})"

        class="my-2"
      >
      {{ $t("areas")}}
      </v-btn>
       <v-btn
        color="#ffffffab"
        text
                 v-bind:class="{'ar-font':($i18n.locale === 'ar')}"

        class="my-2"
        
          @click="$router.push({path:'news'})"
      >
      {{ $t("news")}}
      </v-btn>
      <v-btn
        color="#ffffffab"
        text
        class="my-2"
                 v-bind:class="{'ar-font':($i18n.locale === 'ar')}"

          @click="$router.push({path:'contact'})"
      >
      {{ $t("contact")}}
      </v-btn>
    </v-row>
  </v-footer>
                 </v-col>
    
        </v-row>
      </v-container> -->
    <v-container>
        <v-row class="d-sm-flex d-none">
          <v-col lg="3" md="4" cols="12" class="d-flex ">
            <div>
                       <img class="leftLogo" src="@/assets/footer-logo.png" alt="logo" />


             
            </div>
          </v-col>
          <v-col lg="6" md="8" cols="12">
            <v-row>
              <v-col md="4" sm="4" cols="12">
                <div class="conEachFooterSec">
                  <div class="header"    v-bind:class="$i18n.locale === 'ar' ? ' footerHeadAr' : 'footerHead'" > {{ $t('about')}}</div>
                  <v-divider>
                  </v-divider>
                  <div class="conOFLinks">
                    <v-btn
                      text
                      tile
                      :ripple="false"
                      class="eachLinkFooter"
                      :to="{ name: 'WhoWeAre' }"
                    >
                    {{ $t('WhoWeAre')}}
                    
                    </v-btn>
                    <v-btn
                      text
                      tile
                      :ripple="false"
                      class="eachLinkFooter"
                      :to="{ name: 'MissionVisionValues' }"
                    >
                  
                      
                    {{ $t('mission & vision')}}
                    </v-btn>
              
                  </div>
                </div>
              </v-col>
              <v-col md="4" sm="4" cols="12">
                <div class="conEachFooterSec">
                  <div class="header"    v-bind:class="$i18n.locale === 'ar' ? ' footerHeadAr' : 'footerHead'">{{ $t('areas')}}</div>
                  <v-divider>
                  </v-divider>
                  <div class="conOFLinks">
                    <v-btn
                      text
                      tile
                      :ripple="false"
                      class="eachLinkFooter"
                      :to="{ name: 'areas' , query: { id: '1' }}"
                    >
                     {{ $t('area-txt1')}}
                    </v-btn>
                    <v-btn
                      text
                      tile
                      :ripple="false"
                      class="eachLinkFooter"
                      :to="{ name: 'areas' , query: { id: '2' } }"
                    >
                      {{ $t('area-txt2')}}
                    </v-btn>
                    <v-btn
                      text
                      tile
                      :ripple="false"
                      class="eachLinkFooter"
                      :to="{ name: 'areas' , query: { id: '3' }}"
                    >
                   {{ $t('area-txt3')}}
                    </v-btn>
                       <v-btn
                      text
                      tile
                      :ripple="false"
                      class="eachLinkFooter"
                      :to="{ name: 'areas' , query: { id: '4' }}"
                    >
               {{ $t('area-txt4')}}
                    </v-btn>
                  </div>
                </div>
              </v-col>
              <v-col md="4" sm="4" cols="12">
                <div class="conEachFooterSec">
                  <div class="header"    v-bind:class="$i18n.locale === 'ar' ? ' footerHeadAr' : 'footerHead'">{{ $t('media')}}</div>
                  <v-divider>
                  </v-divider>
                  <div class="conOFLinks">
                 <v-btn
                         :to="{ name: 'news' }"
                      text
                      tile
                      :ripple="false"
                      class="eachLinkFooter"
                    >
                      {{ $t('News & Events')}}
                  
                    </v-btn>
                    <v-btn
                      :to="{ name: 'gallery' }"
                      text
                      tile
                      :ripple="false"
                      class="eachLinkFooter"
                    >
                    {{ $t('gallery')}}
                   
                    </v-btn>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col lg="3" md="12" cols="12">
            <div class="conOfSubmitForm">
              <div class="header"   v-bind:class="$i18n.locale === 'ar' ?'footerHeadAr' : 'footerHead'">{{ $t('contact')}}</div>
              <v-divider>
                  </v-divider>
              <div class="conOFLinks">
              <v-btn
                      href="tel:966508852448"
                      text
                      tile
                      :ripple="false"
                      class="eachLinkFooter"
                    >
                  <v-icon style="color: #A1D683 ; font-size:20px;" dark >
                  mdi-phone
                </v-icon> + 966508852448
                    </v-btn>
                    <v-btn
                     
                      text
                      tile
                      :ripple="false"
                      class="eachLinkFooter"
                    >
                      <v-icon style="color: #A1D683 ; font-size:20px;" dark >
                  mdi-map-marker
                </v-icon>
                {{ $t('worodAddress')}}
            
                    </v-btn>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row class="d-flex d-sm-none justify-center align-center">
          <v-col cols="12">
            <div class="d-flex justify-center">
                       <img class="leftLogo" src="@/assets/footer-logo.png" alt="logo" />


             
            </div>
          </v-col>
         <v-list>
        <v-list-group
          v-for="item in items"
          :key="$i18n.locale === 'ar' ? item.title.AR : item.title.En"
          v-model="item.active"
          :class="item.single ? 'hidden-lg-and-up' : ''"
        >
         
          <template v-slot:activator>
      
            <v-btn
              text
              :to="item.routeName ? { name: item.routeName } : ''"
                :class="[item.className, item.addActive]"
              :ripple="false"
              :exact="item.routeName == 'home'"
            >

              <v-list-item-content>
                <v-list-item-title v-text="$i18n.locale === 'ar' ? item.title.AR : item.title.En"></v-list-item-title>
                                                  <div class="divider"></div> 

              </v-list-item-content>

            </v-btn>
             
          </template>

          <v-list-item
            v-for="child in item.items"
            :key="$i18n.locale === 'ar' ? child.titleAr : child.title"
            :to="child.routeName.query ? { name: child.routeName.name, query: {id: child.routeName.query.id} } : { name: child.routeName }"
            link
          >
            <v-list-item-content>
              <v-list-item-title class="childfooter" v-text="$i18n.locale === 'ar' ? child.titleAr : child.title"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      
      </v-list>
        </v-row>
      </v-container>
    </section>
</template>
<script>
  export default {
    data: () => ({
      links: [
        'Home',
        'About Us',
        'Activites',
        'News & Events',
        'Contact Us',
      ],
       items: [
     
      
        {
          active: false,
             title: {En:"ABOUT US", AR:"نبذه عنا"},
          addActive: "",
          items: [
            { title: "Who We Are",  titleAr:"من نحن" ,routeName: "WhoWeAre" },
            { title: "Mission & Vision & Values", titleAr:" مهمتنا و رؤيتنا و قيمنا" , routeName: "MissionVisionValues" },
          
          ],
        },
        {  active: false,
           title: {En:"Areas", AR:"المناطق"},
          routeName: "areas",
          addActive: "",
           items: [
       
            { title: "History & Heritage", titleAr:"التاريخ والحضاره" , routeName:{ name: 'areas' , query: { id: '1' }} },
             { title: "Family & Child", titleAr:"الاطفال و العائلة" , routeName: { name: 'areas' , query: { id: '2' }} },
         { title: "Arts & Educational", titleAr:"الفن والتعليم" , routeName:{ name: 'areas' , query: { id: '3' }} },
                  { title: "Youth & Technology", titleAr:"الشباب والتكنولوجيا " , routeName: { name: 'areas' , query: { id: '4' }} },

          ],
        },
     
        {
          active: false,
          title: {En:"Media", AR:" وسائط"},
          addActive: "",
          items: [
       
            { title: "EVENTS & NEWS", titleAr:"اخبار و المناسبات" , routeName: "news" },
             { title: "Gallery", titleAr:"معرض الصور " , routeName: "gallery" },
        
          ],
        },
        {
           active: false,
          title: {En:"CONTACT US", AR:"تواصل معنا"},
          className: "contactUs",
          addActive: "",
           items: [
       
            { title: "+966508852448", titleAr:"+966508852448" , routeName: "" },
             { title: "283 Altayf -Saudi Arabia", titleAr:"283 مدينة الطائف - المملكة العربيه السعودية " , routeName: "" },
        
          ],
        },
        // {
        //   title: "Apply for membership",
        //   routeName: "register",
        //   className: "applyForMembership",
        //   single: true,
        // },
      ],
    }),
  }
</script>
<style lang="scss" scoped>
.conOfFooter{
      background: url('~@/assets/foot-pattern.png'), #360032;
    color: #ffffffab;
    background-position: top left;
    background-repeat: no-repeat;
   padding: 20px 0px;
    display: flex;
    align-items: center;
    font-family:"Philosopher-Regular";
}
.conOfFooterAr{
   font-family:"gess-Light" !important;
     background: url('~@/assets/foot-pattern.png'), #360032;
    color: #ffffffab;
    background-position: top left;
    background-repeat: no-repeat;
   padding: 20px 0px;
    display: flex;
    align-items: center;
}
.rightLogo{
    width:200px
}
.leftLogo{
        height:120px;

}
.foot{
 display: flex;
 justify-content: end;
}
.ltr{
  direction: ltr;
}
.rtl{
  direction: rtl;
}
.header{
   color: #ffff;
}
.footerHead{
  color: #ffff;
       font-family: 'Philosopher-Bold';
       text-transform: uppercase;
}
.footerHeadAr{
  color: #ffff;
  font-family: "gess-Light";
}
.eachLinkFooter{
  color: rgba(255, 255, 255, 0.507);
     font-size: 12px;
    display: block;
    justify-content: flex-start;
    height: 30px !important;
}
.theme--light.v-divider {
  
    width: 35px;
    border: 1px solid #F167A7;
    margin-bottom: 10px;
}
.divider{
    width: 35px;
    border: 1px solid #F167A7;
    flex: initial;
}
.childfooter{
      color: rgba(255, 255, 255, 0.507) !important;
}
::v-deep{
  .theme--light.v-btn--active::before,
  .theme--light.v-btn:hover::before {
     opacity: 0;
}
a.eachLinkFooter.v-btn.v-btn--router.v-btn--text.v-btn--tile.theme--light.v-size--default {
  padding: 0;
}
a.eachLinkFooter.v-btn.v-btn--text.v-btn--tile.theme--light.v-size--default ,
button.eachLinkFooter.v-btn.v-btn--text.v-btn--tile.theme--light.v-size--default{
    padding: 0;
}
.v-list.v-sheet.theme--light {
  background: transparent;
}
    .v-list-item .v-list-item__title{
    line-height: 1.2;
    color: #fff;
        min-width: 100px;
    margin: auto;
    text-align: center;
        letter-spacing: 0;

}
.v-list-item .v-list-item__subtitle {
  color:#5212;
}
.v-icon.v-icon {
   color: #fff;
}
.v-list-group .v-list-group__header .v-list-item__icon.v-list-group__header__append-icon{
  justify-content: flex-start;
}
.v-list-item__icon:last-of-type:not(:only-child){
  margin-left: 0px;
}
.v-btn:not(.v-btn--round).v-size--default {
  padding: 0;
}
.v-list .v-list-item--active {
    color: transparent;
}
.v-list-item__content {
justify-content: center;
}

}
</style>