/* eslint-disable no-debugger */
<template >
  <div v-bind:class="$i18n.locale === 'ar' ?' rtl' : 'ltr'" >
    <v-app-bar class="navbar headerNavBar" color="transparent" absolute >
      <v-app-bar-nav-icon
        @click="drawer = true"
        class="hidden-lg-and-up"
      ></v-app-bar-nav-icon>
      <!-- {{ $t('header.Home') }} -->

    
      <v-spacer class="hidden-md-and-down"></v-spacer>

      <v-toolbar-items class="hidden-md-and-down">
        <v-menu
          :close-on-content-click="false"
          v-for="item in items"
          :key="$i18n.locale === 'ar' ? item.title.AR : item.title.En"
          open-on-hover
          bottom
          offset-y
        >
          <template v-slot:activator="{ on }">
      
            <v-btn
              v-on="on"
              :to="item.routeName ? { name: item.routeName } : ''"
              :class="[item.className, item.addActive]"
              :ripple="false"
              :exact="item.routeName == 'home'"
            >
              {{ $i18n.locale === 'ar' ? item.title.AR : item.title.En }}
              <v-icon v-if="!item.single"> mdi-chevron-down </v-icon>
            </v-btn>
          </template>
          <v-list dense v-if="item.items">
            <v-list-item-group v-model="selectedItem" class="conChildItem">
              <v-list-item v-for="child in item.items" :key="$i18n.locale === 'ar' ? child.titleAr : child.title">
                <v-list-item-content>
                  <v-btn
                    :to="child.routeName ? { name: child.routeName } : ''"
                    text
                    tile
                    :ripple="false"
                  >
                    <v-list-item-title
                      class="titleOfChildNav"
                       v-bind:class="$i18n.locale === 'ar' ?' rtl' : 'ltr'"
                      v-text="$i18n.locale === 'ar' ? child.titleAr : child.title"
                    ></v-list-item-title>
                  </v-btn>
                </v-list-item-content>
              </v-list-item>
           
            </v-list-item-group>
          </v-list>
        </v-menu>
      </v-toolbar-items>
    
   <img @click="dialog=true" src="@/assets/menu.png"  class="hidden-lg-and-up px-2">
        <v-btn class="lang-btn" @click="setLocale('en')"  v-if="$i18n.locale ==='ar'" v-bind:class="$i18n.locale === 'ar' ?' ltr' : ''">
         
       
        English
         <v-icon class="web-icon">mdi-web</v-icon>
      </v-btn>
      <v-btn class="lang-btn" @click="setLocale('ar')" v-if="$i18n.locale ==='en'"  v-bind:class="$i18n.locale === 'en' ?' rtl' : 'ltr'">
      
      عربى
       <v-icon class="web-icon">mdi-web</v-icon>
      </v-btn>
        
    </v-app-bar>
     <div class="text-center">
    
  </div>
    <!-- responsive -->
 <v-dialog 
    content-class="dialog-style"
      v-model="dialog"
      width="600"
      overlay-opacity="0.91"
      overlay-color="#000"
     
    >
         
  <v-list>
        <v-list-group
          v-for="item in items"
          :key="$i18n.locale === 'ar' ? item.title.AR : item.title.En"
          v-model="item.active"
          :class="item.single ? 'hidden-lg-and-up' : ''"
        >
      
          <template v-slot:activator>
            <v-btn
           
              text
              :to="item.routeName ? { name: item.routeName } : ''"
                :class="[item.className, item.addActive]"
              :ripple="false"
              :exact="item.routeName == 'home'"
              @click="dialog=false"
            >
          
              <!-- item.single -->
              <!-- :class="item.className" -->

              <v-list-item-content>
                <v-list-item-title  v-text="$i18n.locale === 'ar' ? item.title.AR : item.title.En"></v-list-item-title>
                  <div class="divider">
                  </div>
              </v-list-item-content>
            </v-btn>
          </template>

          <v-list-item
            v-for="child in item.items"
            :key="$i18n.locale === 'ar' ? child.titleAr : child.title"
            :to="child.routeName ? { name: child.routeName } : ''"
            link
             @click="dialog=false"
          >
            <v-list-item-content>
              <v-list-item-title v-text="$i18n.locale === 'ar' ? child.titleAr : child.title"></v-list-item-title>
                  <div class="divider">
                  </div>
            </v-list-item-content>
          </v-list-item>

        </v-list-group>
      
      </v-list>
 
    </v-dialog>
  </div>
</template>
<script>
  export default {
    data () {
      return {
        lang:this.$i18n.locale,
        dialog: false,
        drawer:false,
        mobile:6664440000,
      selectedItem: '',
      items: [
        { active: false,
         title: {En:"Home", AR:"الرئيسية"},
          routeName: "home",
          addActive: "",
          single: true,
        },
      
        {
          active: false,
             title: {En:"ABOUT US", AR:"نبذه  عنا"},
          addActive: "",
          items: [
            { title: "Who We Are",  titleAr:"من نحن" ,routeName: "WhoWeAre" },
            { title: "Mission & Vision & Values", titleAr:" مهمتنا و رؤيتنا و قيمنا" , routeName: "MissionVisionValues" },
          
          ],
        },
        {  active: false,
           title: {En:"Areas", AR:"المناطق"},
          routeName: "areas",
          addActive: "",
          single: true,
        },
     
        {
          active: false,
          title: {En:"Media", AR:" وسائط"},
          addActive: "",
          items: [
       
            { title: "EVENTS & NEWS", titleAr:"اخبار و المناسبات" , routeName: "news" },
             { title: "Gallery", titleAr:"معرض الصور " , routeName: "gallery" },
        
          ],
        },
        {
           active: false,
          title: {En:"CONTACT US", AR:"تواصل معنا"},
          routeName: "contact",
          className: "contactUs",
          addActive: "",
          single: true,
        },
        // {
        //   title: "Apply for membership",
        //   routeName: "register",
        //   className: "applyForMembership",
        //   single: true,
        // },
      ],
      }
    },
      methods: {
    setLocale(locale) {
   this.lang=locale
      this.$i18n.locale = locale;
      this.$router.push({
        params: { lang: locale },
      });
    },
  },
 

   watch: {

    $route(to) {
   
      this.items.forEach(function (el) {
        el.addActive = "";
      });
    
                  this.items.filter(function (item) {
        if (item.items) {
          item.items.find((el) => {
            if (el.routeName == to.name) {
              item.addActive = "addActive";
              console.log(item);
             
            }
          });
        }
      });
      
       },

    deep: true,
    immediate: true,
  },
  }
</script>
<style lang="scss" scoped>
.divider{
    width: 35px;
    border: 1px solid #F167A7;
    flex: initial;
    display: none;
}
.menuweb{
  width: 100%;

  position: absolute;
  top: 1%;
  right: 1%;
  color: #fff;
  z-index: 2;
}
.swap{
  right: 50px;
  left: unset;
  direction: rtl;
}
.menumob{
  width: 100%;
 background: #360032;
  position: fixed;
  color: #fff;
  z-index: 2;
}
.fixedAndIndexed{
      height: 100%;
    top: 0px;
    left: 0px;
    transform: translateX(0%);
     color: #fff;
    background: #481c4fe0 !important;
    width: 100% !important;
}
.resImg{
  width: 70px;
}
.web-icon{

  color: #ffff;
  font-size: 15px;
}
.menuText{
  text-align: left;
letter-spacing: 4.8px;
color: #FFFFFF;
font-size: 12px;
font-family: "Philosopher-Bold";
}
.item-text{
font-family: "Philosopher-Bold" !important;
  font-size: 40px !important;

}
.rtl{
  font-family: "gess-Medium" !important;
  direction: rtl;
}
.ltr{
  font-family: "Philosopher-Regular" !important;
}
.menuImg{
  width:35px
}
.menu{
  cursor: pointer;
}
.close{
    cursor: pointer;

  width: auto;
  
}

.list-style{
  background: transparent !important;
}
 .bg-active {
    color: #A1D683 !important;
  }
.v-list-item--link:before {
  background-color: transparent;
}

.v-list-item.v-list-item--link.theme--dark.highlighted{
        color: #A1D683 !important

}

.v-list-item__title.item-text.highlighted{
        color: #A1D683 !important
}
::v-deep{

  .v-dialog.dialog-style.v-dialog--active {
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}
  .v-list-group.hidden-lg-and-up {
  .v-list-group__header .v-list-item__icon.v-list-group__header__append-icon {
    display: none;
  }
}
  .v-list-item--dense .v-list-item__title, .v-list-item--dense .v-list-item__subtitle, .v-list--dense .v-list-item .v-list-item__title, .v-list--dense .v-list-item .v-list-item__subtitle {
 
    color: white;
}
  .v-toolbar__content {
    margin-left: 50px;
    margin-right: 50px;
}
  .v-list{
    padding: 0;
  }
  a.v-btn--active.v-btn.v-btn--router.v-btn--text.v-btn--tile.theme--light.v-size--default {
      
    background: #2E2855;
}
.v-list-item--dense .v-list-item__content, .v-list--dense .v-list-item .v-list-item__content {
    padding: 0; 
}
.v-list-item {
  padding: 0;
}

 .addActive{
   .v-list-item__title {
      color: #A1D683 !important;
}
 .divider{
    width: 35px;
    border: 1px solid #F167A7;
    flex: initial;
    display: block;
}

    .v-list-item.v-list-item--link.theme--light {
   color: #fff;
    background: #2E2855;
}
 }

.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
    color: #fff;
}

  .v-sheet.v-toolbar:not(.v-sheet--outlined),.v-sheet.v-app-bar.v-toolbar:not(.v-sheet--outlined),.v-btn--is-elevated { 
    box-shadow: unset;
  }
  .v-btn:not(.v-btn--round).v-size--default {
     color: #ffff;

  }
  .theme--light.v-btn.v-btn--has-bg {
    background: unset;
  }
 
a.v-btn--active.v-btn.v-btn--is-elevated.v-btn--has-bg.v-btn--router.theme--light.v-size--default {
  color: #a1d683;
     font-family: 'Philosopher-Bold';

}

button.v-btn.v-btn--is-elevated.v-btn--has-bg.theme--light.v-size--default{
  letter-spacing: 0px !important;
}
.theme--light.v-btn:hover::before ,.theme--light.v-btn-active:hover::before  {
  opacity: 0;
}
.theme--light.v-btn--active:focus::before,
  .theme--light.v-list-item--active:hover::before,
  .theme--light.v-list-item--active::before,
  .theme--light.v-btn--active:hover::before,
  .theme--light.v-btn--active::before { 
    opacity: 0;
}
.v-menu__content {
  border-radius: 10px !important;
}
.theme--light.v-list {
    background: #2e28558c;
 
    color: #ffff;
 
}
header.navbar.headerNavBar.v-sheet.theme--light.v-toolbar.v-toolbar--absolute.v-app-bar.transparent {
  margin-top: 10px !important;
  
}
@media only screen and (max-width: 768px) {
  .v-list .v-list-item--active {
    color: transparent;
}
  .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
    color: transparent !important;
}
  .v-list-item .v-list-item__title, .v-list-item .v-list-item__subtitle {
    line-height: 1.2;
    color: #ffffff;
    font-size: 25px;
    font-family: 'Philosopher-Regular';
    text-align: initial;
    letter-spacing: 0;
}
 a.v-btn.v-btn--router.v-btn--text.theme--light.v-size--default,
  button.v-btn.v-btn--text.theme--light.v-size--default{
      padding: 0;

  }
a.v-btn--active.v-btn.v-btn--router.v-btn--text.theme--light.v-size--default {
  padding: 0;
  .v-list-item__title {
       color: #A1D683 ;
  }
  .divider{
    width: 35px;
    border: 1px solid #F167A7;
    flex: initial;
    display: block;
}

}
  .v-dialog__content {
    align-items: center;
    display: flex;
    height: 100vh !important;
    justify-content: center;
    margin-left: 20%;
   
}
    .v-toolbar__content {
   margin:0;
}
.v-toolbar__content, .v-toolbar__extension {
    align-items: center;
    display: flex;
    position: relative;
    z-index: 0;
    justify-content: end;
}
/* button.v-app-bar__nav-icon.hidden-lg-and-up.v-btn.v-btn--icon.v-btn--round.theme--light.v-size--default{span.v-btn__content {
  background: url('~@/assets/menu.png');
      background-repeat: no-repeat;
}} */
.theme--light.v-list {
    background: transparent;
    color: #ffff;
}

.mdi-menu::before {
    content: unset;
}
.v-icon.v-icon{
  color: #fff;
}
 .v-application--is-ltr .v-list-item__avatar:last-of-type:not(:only-child), .v-application--is-ltr .v-list-item__icon:last-of-type:not(:only-child) {
    margin-left: 0px !important;
}

}

}
.addActive{
  color: #a1d683 !important;
   font-family: 'Philosopher-Bold';
}
.addActiveAr{
  color: #a1d683 !important;
   font-family: 'gess-Bold';
}
@media only screen and (max-width: 768px) {
.item-text{

  font-size: 20px !important;

}
 html:lang(ar){
     .v-list-item .v-list-item__title, .v-list-item .v-list-item__subtitle {
    line-height: 1.2;
    color: #ffffff;
    font-size: 25px;
    text-align: initial;
    letter-spacing: 0;
    font-family: "gess-light";
}
 }
}
html:lang(ar){
  .addActive{
 color: #a1d683 !important;
   font-family: 'gess-Bold';
}
a.v-btn--active.v-btn.v-btn--is-elevated.v-btn--has-bg.v-btn--router.theme--light.v-size--default {
  color: #a1d683;
     font-family: 'gess-Bold';

}
  .v-dialog__content {
 direction: rtl;
    margin-left: -20%;
   
}

}
</style>
